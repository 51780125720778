import React from 'react';
import { Box, Typography, Button, Container, Grid, Card, CardContent } from '@mui/material';
import { styled } from '@mui/system';
import StarIcon from '@mui/icons-material/Star';
import Testimonials from './Testimonials';
const TestimonialSection = styled(Box)({
  minHeight: '100vh',
  // backgroundImage: 'url("/src/images/beach-5406139_1280.jpg")',
  // backgroundSize: 'cover',
  // backgroundPosition: 'center',
  background:'#fcf8f3',
  padding: '40px 0',
  position: 'relative',
});

const ContentOverlay = styled(Box)({
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  // backgroundColor: 'rgba(255, 255, 255, 0.3)',
});

const StyledCard = styled(Card)({
  backgroundColor: '#5887ff',
  borderRadius: '15px',
  height: '100%', // Ensure full height
  display: 'flex',
  flexDirection: 'column',
  color:'white',
});

const StyledCardContent = styled(CardContent)({
  flexGrow: 1, // Allow content to expand and fill the card
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-around',
});

const StyledButton = styled(Button)({
  backgroundColor: '#FFEB3B',
  color: '#000',
  borderRadius: '20px',
  padding: '10px 20px',
  '&:hover': {
    backgroundColor: '#f00',
    color:'white',
  },
});

const TestimonialContent = ({ openModal }) => (
  <TestimonialSection>
    <ContentOverlay />
    <Container sx={{ position: 'relative', zIndex: 1 }}>
      <Typography variant="h3" align="center" gutterBottom sx={{ fontWeight: 'bold', marginBottom: '80px' }}>
        Our clients speak for themselves...
      </Typography>
      
      <Grid container justifyContent="center">
      <Testimonials/>
      </Grid>
      <Box textAlign="center" marginTop="40px">
        <StyledButton onClick={openModal} variant="contained">LET'S COLLABORATE</StyledButton>
      </Box>
    </Container>
  </TestimonialSection>
);

export default TestimonialContent;