import React from 'react';
import { Box, Container, Typography, Button, Link, Grid, Divider } from '@mui/material';
import { styled } from '@mui/material/styles';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import InstagramIcon from '@mui/icons-material/Instagram';

const StyledFooter = styled('footer')(({ theme }) => ({
  backgroundColor: '#ff3131',
  color: '#fcf8f3',
  minHeight: '100vh',
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
}));

const StyledButton = styled(Button)(({ theme }) => ({
  color: 'black',
  backgroundColor: theme.palette.primary.contrastText,
  '&:hover': {
            backgroundColor: '#ffde00',
            color: 'white',
          },
}));

const VerticalDivider = styled(Divider)(({ theme }) => ({
  backgroundColor: theme.palette.primary.contrastText,
  width: '1px',
  height: '100%',
}));

const HorizontalDivider = styled(Divider)(({ theme }) => ({
  backgroundColor: theme.palette.primary.contrastText,
  width: '100%',
  height: '1px',
  margin: theme.spacing(4, 0),
}));

const Footer = () => {
  return (
    <StyledFooter>
      <Container sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', width: '100%' }} maxWidth={false} disableGutters>
        {/* First Row */}
        <Grid container spacing={4} sx={{ mb: 4, mt: 4 }}>
          <Grid item xs={12} md={6} sx={{ position: 'relative' }}>
            <Typography variant="h3" gutterBottom>
              Have a Project in your mind?
            </Typography>
           
            <Typography variant="body1" gutterBottom>
            For more business inquiries, 
            </Typography>
            <Typography variant="body2">
            Contact us at <a style={{textDecoration:'none', color:'inherit'}} href='mailto:hello.bmakers@gmail.com'>hello.bmakers@gmail.com</a><br />
            Number- <a style={{textDecoration:'none', color:'inherit'}} href='tel:9920656924'>9920656924</a>
            </Typography>
            {/* Vertical Divider */}
            <Box sx={{ position: 'absolute', right: 0, top: 0, height: '100%', display: { xs: 'none', md: 'block' } }}>
              <VerticalDivider orientation="vertical" />
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
          <Typography variant="h4" gutterBottom>
          Catch up with us!
            </Typography>
            <Typography variant="h6" gutterBottom>
            For general queries,
            </Typography>
            <Typography variant="body2">
            <a style={{textDecoration:'none', color:'inherit'}} href='mailto:hello.bmakers@gmail.com'>hello.bmakers@gmail.com</a><br />
            Number- <a style={{textDecoration:'none', color:'inherit'}} href='tel:9920656924'>9920656924</a>
            
            </Typography>
          </Grid>
        </Grid>

        {/* Horizontal Divider */}
        <HorizontalDivider />

        {/* Second Row */}
        <Grid container spacing={4} sx={{ mb: 4, flexGrow: 1 }}>
          <Grid item xs={12} md={8} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', position: 'relative', alignItems:'flex-start' }}>
            <Typography variant="h1" component="h2" gutterBottom sx={{ fontSize: '8rem', fontWeight: 'bold' }}>
              Say hi!
            </Typography>
            <StyledButton variant="contained">
              Send a Message
            </StyledButton>
            {/* Vertical Divider */}
            <Box sx={{ position: 'absolute', right: 0, top: 0, height: '100%', display: { xs: 'none', md: 'block' } }}>
              <VerticalDivider orientation="vertical" />
            </Box>
          </Grid>
          <Grid item xs={12} md={4} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
            <Typography variant="body2" gutterBottom>
              Chapel House<br />
              1-3 Chapel Street<br />
              Guildford<br />
              GU1 3UH
            </Typography>
            <Typography variant="body2" gutterBottom sx={{ mt: 2 }}>
              131 - 151<br />
              Great Titchfield Street<br />
              London<br />
              W1W 5BB
            </Typography>
          </Grid>
        </Grid>

        {/* Horizontal Divider */}
        <HorizontalDivider />

        {/* Third Row */}
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Box>
            <FacebookIcon sx={{ mr: 1 }} />
            <TwitterIcon sx={{ mr: 1 }} />
            <LinkedInIcon sx={{ mr: 1 }} />
            <InstagramIcon />
          </Box>
          <Box>
            <Link href="#" color="inherit" underline="always" sx={{ mr: 2 }}>Terms and Conditions</Link>
            <Link href="#" color="inherit" underline="always" sx={{ mr: 2 }}>Privacy Policy</Link>
            <Link href="#" color="inherit" underline="always">Cookies</Link>
          </Box>
        </Box>
       
      </Container>
      <Typography variant="body2" sx={{ textAlign: 'center' }}>
      © {new Date().getFullYear()} B.Makers. All rights reserved.
</Typography>

      <Typography variant="body2" sx={{ mt: 2}} display="flex" justifyContent="center" alignItems="center">
          Developed by&nbsp;  <a style={{textDecoration:'none', color:'inherit'}} href='https://wwww.mejona.in'> Mejona Technology LLP</a>
        </Typography>
    </StyledFooter>
  );
};

export default Footer;
