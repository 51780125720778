import React from 'react';
import { Box, Typography, Button, Container, Grid } from '@mui/material';
import { styled } from '@mui/system';
import GradientBackground from './GradientBackground'; // Adjust the path as necessary

const PromiseSection = styled(Box)({
  minHeight: '100vh',
  backgroundColor: '#ff00ff2b', 
  color: '#ffde00',
  padding: '40px 0',
  display:'flex',
  justifyContent:'center',
  alignItems:'center'
});

const ImageContainer = styled(Box)({
  borderRadius: '20px',
  overflow: 'hidden',
  height: '100%',
});

const StyledButton = styled(Button)({
  borderRadius: '30px',
  padding: '10px 30px',
  '&:hover': {
    backgroundColor: 'red',
    color: 'white',
  },
});

const PromiseContent = () => (
  <GradientBackground>
    <PromiseSection>
      <Container>
        <Grid container spacing={4} alignItems="center">
          <Grid item xs={12} md={6}>
            <Typography variant="h2" component="h1" gutterBottom sx={{ 
              fontWeight: 'bold', 
              fontFamily: 'Arial, sans-serif',
              fontSize: { xs: '3rem', md: '4rem' },
              marginBottom: '30px'
            }}>
              OUR COMMITMENT
            </Typography>
            <Typography variant="h4" gutterBottom sx={{ fontWeight: 'light', marginBottom: '20px' }}>
            Your brand's success is our unwavering commitment!
            </Typography>
            <Typography variant="body1" paragraph sx={{ marginBottom: '30px' }}>
            We take the time to understand your vision and craft tailored strategies that resonate with your goals. Every project is handled with care and precision, ensuring that our work not only meets but surpasses your expectations. We stand by our promise to be a dependable partner, dedicated to supporting your growth and achieving lasting results.
            </Typography>
            <StyledButton variant="contained">LEARN MORE</StyledButton>
          </Grid>
          <Grid item xs={12} md={6}>
            <ImageContainer>
              <img src="/src/images/woman-5542457_1280.jpg" alt="Woman on beach" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
            </ImageContainer>
          </Grid>
        </Grid>
      </Container>
    </PromiseSection>
  </GradientBackground>
);

export default PromiseContent;
