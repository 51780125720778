import React from "react";
import { Box, Typography, Button, Container, Grid } from "@mui/material";
import { styled } from "@mui/system";
import { motion } from "framer-motion";
import CreateIcon from "@mui/icons-material/Create";
import SocialMediaIcon from "@mui/icons-material/Share";
import BusinessIcon from "@mui/icons-material/Business";
import GradientBackground from "./GradientBackground";
// import ImageShapeDisplay from "./ImageShapeDisplay";

const ImageShapeDisplay = ({ imageSrc, text, width, height }) => (
  <Box
    sx={{
      position: 'relative',
      width: width,
      height: height,
      margin: 'auto',
    }}
  >
    <Box
      component="img"
      src={imageSrc}
      alt="Cloud Shape"
      sx={{
        width: '100%',
        height: '100%',
        objectFit: 'contain',
      }}
    />
    <Box
      sx={{
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      {text}
    </Box>
  </Box>
);

const StyledButton = styled(Button)(({ theme }) => ({
  backgroundColor: "#FFD93D",
  color: "#000",
  borderRadius: "30px",
  padding: "8px 16px",
  fontWeight: "bold",
  textTransform: "uppercase",
  transition: "all 0.3s ease",
  fontSize: "0.8rem",
  "&:hover": {
    backgroundColor: "#FF6B6B",
    color: "#fff",
    transform: "translateY(-3px)",
    boxShadow: "0 3px 10px rgba(0,0,0,0.2)",
  },
}));

const CloudContent = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100%',
  padding: '10px',
  textAlign: 'center',
});

const ServiceItem = ({ title, buttonText, icon, onClick, cloudImageSrc, size }) => (
  <Grid item xs={12} sm={6} md={4}>
    <motion.div whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.95 }}>
      <ImageShapeDisplay
        imageSrc={cloudImageSrc}
        width={size.width}
        height={size.height}
        text={
          <CloudContent>
            {icon}
            <Typography
              variant="h6"
              component="h2"
              gutterBottom
              sx={{
                fontWeight: "bold",
                fontFamily: '"Poppins", sans-serif',
                marginTop: 1,
                marginBottom: 1,
                color: "#4A4A4A",
                fontSize: "1rem",
              }}
            >
              {title}
            </Typography>
            {/* <StyledButton onClick={onClick}>{buttonText}</StyledButton> */}
          </CloudContent>
        }
      />
    </motion.div>
  </Grid>
);

const ServicesDisplay = ({ openModal }) => {
  const cloudImageSrc = "https://cdn.pixabay.com/photo/2013/07/12/18/55/cloud-153992_1280.png";

  const cloudSizes = [
    { width: 270, height: 250  },
    { width: 280, height: 230 },
    { width: 275, height: 210 },
  ];

  return (
    <GradientBackground
      minHeight="100vh"
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <Container>
        <motion.div
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          <Typography
            variant="h2"
            component="h1"
            align="center"
            gutterBottom
            sx={{
              fontWeight: "bold",
              fontFamily: '"Poppins", sans-serif',
              color: "#fff",
              marginBottom: 6,
            }}
          >
            Our Services
          </Typography>
        </motion.div>
        <Grid
          container
          spacing={4}
          justifyContent="center"
          alignItems="center"
        >
          <ServiceItem
            title="Content Creation"
            buttonText="Learn More"
            icon={<CreateIcon sx={{ fontSize: 40, color: "#E6E6FA" }} />}
            cloudImageSrc={cloudImageSrc}
            size={cloudSizes[0]}
          />
          <ServiceItem
            onClick={openModal}
            title="Social Media Management"
            buttonText="Get Started"
            icon={<SocialMediaIcon sx={{ fontSize: 40, color: "#FDB0C0" }} />}
            cloudImageSrc={cloudImageSrc}
            size={cloudSizes[1]}
          />
          <ServiceItem
            onClick={openModal}
            title="Graphic Design"
            buttonText="Book Now"
            icon={<BusinessIcon sx={{ fontSize: 40, color: "#FFD93D" }} />}
            cloudImageSrc={cloudImageSrc}
            size={cloudSizes[2]}
          />
        </Grid>
      </Container>
    </GradientBackground>
  );
};

export default ServicesDisplay;