// theme.js
import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#5887ff",
    },
    secondary: {
      main: "#ffd700", // Yellow
    },
    background: {
      default: "#ff3131",
    },
  },
  typography: {
    fontFamily: '"Montserrat-Variable", "Montserrat", "Roboto", "Arial", sans-serif',
    h1: {
      fontFamily: '"HelveticaNeue-Black", "Arial", sans-serif',
      fontWeight: 900,
      fontSize: "2.5rem",
    },
    h2: {
      fontFamily: '"HelveticaNeue-Bold", "Arial", sans-serif',
      fontWeight: 700,
      fontSize: "2rem",
    },
    h3: {
      fontFamily: '"HelveticaNeue-Medium", "Arial", sans-serif',
      fontWeight: 500,
      fontSize: "1.75rem",
    },
    h4: {
      fontFamily: '"HelveticaNeue-Roman", "Arial", sans-serif',
      fontWeight: 400,
      fontSize: "1.5rem",
    },
    h5: {
      fontFamily: '"HelveticaNeue-Light", "Arial", sans-serif',
      fontWeight: 300,
      fontSize: "1.25rem",
    },
    h6: {
      fontFamily: '"HelveticaNeue-Thin", "Arial", sans-serif',
      fontWeight: 100,
      fontSize: "1rem",
    },
    body1: {
      fontWeight: 400,
      fontSize: "1rem",
    },
    body2: {
      fontWeight: 400,
      fontSize: "0.875rem",
    },
    button: {
      fontWeight: 600,
      textTransform: 'none',
    },
    subtitle1: {
      fontWeight: 500,
    },
    subtitle2: {
      fontWeight: 500,
    },
  },
  components: {
    MuiContainer: {
      styleOverrides: {
        root: {
          width: "100% !important",
          maxWidth: "100% !important",
          margin: 0,
          padding: "0 16px",
          "@media (min-width:600px)": {
            padding: "0 24px",
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          backgroundColor: "#FFEB3B",
          color: "#000",
          borderRadius: "8px",
          textTransform: "none",
          fontWeight: 600,
          "&:hover": {
            backgroundColor: "red",
            color: "white",
            transform: "translateY(-2px)",
            boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
          },
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: "12px",
          boxShadow: "0 4px 20px rgba(0, 0, 0, 0.1)",
        },
      },
    },
    MuiBox: {
      styleOverrides: {
        root: {
          width: "100%",
        },
      },
    },
  },
});

export default theme;