import React from "react";
import { Box, Typography, Button, Container, Paper } from "@mui/material";
import { styled } from "@mui/material/styles";
import ContactMailIcon from "@mui/icons-material/ContactMail";
import GradientBackground from "./GradientBackground";

const StyledPaper = styled(Paper)(({ theme }) => ({
  width: "100%",
  minHeight: "100vh",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  padding: theme.spacing(4),
  position: "relative",
  overflow: "hidden",
  background: "none",
  boxShadow: "unset",
}));

const StyledButton = styled(Button)(({ theme }) => ({
  //   backgroundColor: '#a7c7e7',
  //   color: '#000',
  padding: "10px 20px",
  fontWeight: "bold",
  transition: "all 0.3s ease",
  "&:hover": {
    // backgroundColor: '#8ab3db',
    transform: "translateY(-2px)",
    boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
  },
}));

const OverlayBox = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: "rgba(0, 0, 0, 0.5)", // Semi-transparent black
  zIndex: 1,
}));

const ServicesAndPricing = ({ openModal }) => {
  return (
    <GradientBackground>
    <Container
      sx={{
        height: "100vh",
        width: "100%",
        background: 'url("src/images/wedding-2081269_1280.jpg")',
        backgroundSize: "cover",
        backgroundPosition: "center",
        position: "relative",
      }}
    >
      <OverlayBox />
      <StyledPaper elevation={3}>
        <Box position="relative" zIndex={2} maxWidth="600px">
          <Typography
            variant="h3"
            component="h1"
            gutterBottom
            sx={{
              fontWeight: 900,
              color: "#fff",
              marginBottom: 4,
              textShadow: "2px 2px 4px rgba(0, 0, 0, 0.7)", // Adding text shadow
            }}
          >
            SERVICES & PRICING
          </Typography>
          <Typography
            variant="h6"
            paragraph
            sx={{
              color: "#ffde00",
              marginBottom: 4,
              textShadow: "1px 1px 3px rgba(0, 0, 0, 0.7)", // Adding text shadow
            }}
          >
            Complete our inquiry form to get detailed insights into our services and pricing.
          </Typography>
          <StyledButton onClick={openModal} variant="contained" startIcon={<ContactMailIcon />}>
            SUBMIT AN INQUIRY
          </StyledButton>
        </Box>
      </StyledPaper>
    </Container>
    </GradientBackground>
  );
};

export default ServicesAndPricing;
