import React from "react";
import styled from "styled-components";

const Testimonials = () => {
  return (
    <StyledWrapper>
      <div className="cards">
        <div className="card pink">
          <img
            src="customer1.jpg"
            alt="Customer 1"
            className="customer-image"
          />
          <p className="review">
            "Hi Benazir, these past 2 months have been incredible! The grid
            aesthetic is on point, and I truly appreciate all your hard work and
            creativity. <br />
            Your attention to detail and ability to capture the essence of our
            brand has made a huge difference. Thank you for all the late nights
            and dedication. Keep up the amazing work!"
          </p>
          <p className="role">- Muskaan Perfumewalla, Founder of Al Kashkha</p>
        </div>
        <div className="card teal">
          <img
            src="customer2.jpg"
            alt="Customer 2"
            className="customer-image"
          />
          <p className="review">
            "We've worked with B Makers for just over two months now, and I have
            to say their integrity, willingness to adapt, and content
            suggestions that align with our brand have been great!
            <br />
            Benazir is always up for trying new things which I appreciate the
            most. Plus, our feed looks more polished and has a better tone and
            voice.
            <br />
            We've had people notice it too which is the biggest
            compliment we can get."
          </p>
          <p className="role">- Anuri Shah, Founder of Uchi Store</p>
        </div>
        <div className="card purple">
          <img
            src="customer3.jpg"
            alt="Customer 3"
            className="customer-image"
          />
          <p className="review">
            "Working with B Makers since the last few months has been a seamless
            process, setting up our social media with excellent aesthetics and a
            well-organised, systematic approach."
          </p>
          <p className="role">- Zeus Palkhivala, Founder of Zeus Palkhivala Architects</p>
        </div>
      </div>
    </StyledWrapper>
  );
};

const StyledWrapper = styled.div`
  .cards {
    display: flex;
    flex-direction: column;
    gap: 15px;
    justify-content: center;
  }

  .card {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    height: auto;
    width: 100%;
    border-radius: 10px;
    color: white;
    cursor: pointer;
    transition: 400ms;
    padding: 20px;
    background: linear-gradient(
      135deg,
      rgba(255, 105, 180, 0.8),
      rgba(255, 183, 77, 0.8)
    );
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(10px);
  }

  .card.pink {
    background: linear-gradient(135deg, #ff66b2, #ffcc99);
  }

  .card.teal {
    background: linear-gradient(135deg, #00bcd4, #4caf50);
  }

  .card.purple {
    background: linear-gradient(135deg, #9c27b0, #f06292);
  }

  .customer-image {
    border-radius: 50%;
    width: 80px;
    height: 80px;
    margin-bottom: 15px;
    border: 3px solid white;
  }

  .review {
    font-size: 1em;
    font-weight: 500;
    margin: 10px 0;
    text-align: left;
  }

  .role {
    font-size: 0.9em;
    color: #e0e0e0;
  }

 
  /* Media query for larger screens */
  @media (min-width: 768px) {
    .cards {
      flex-direction: row;
      
    }
    .card{
      width:30%;
    }
    .card:hover {
    transform: scale(1.1, 1.1);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
  }

  .cards:hover > .card:not(:hover) {
    filter: blur(2px);
    transform: scale(0.9, 0.9);
  }

  }
`;

export default Testimonials;
