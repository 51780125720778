import React from 'react';
import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

const MarqueeContainer = styled(Box)(({ theme }) => ({
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  position: 'relative',
  width: '100%',
  height: '50px', // Adjust height as needed
  backgroundColor: '#e6d46a', // Background color for visibility
  color:'red',
  display: 'flex',
  alignItems: 'center',
  paddingLeft: theme.spacing(2),
}));

const MarqueeText = styled(Typography)(({ theme }) => ({
  display: 'inline-block',
  animation: 'marquee 25s linear infinite',
  whiteSpace: 'nowrap',
  '@keyframes marquee': {
    '0%': {
      transform: 'translateX(100%)',
    },
    '100%': {
      transform: 'translateX(-100%)',
    },
  },
}));

const getCurrentMonthYear = () => {
  const now = new Date();
  const options = { month: 'long', year: 'numeric' };
  return new Intl.DateTimeFormat('en-US', options).format(now);
};

const Marquee = () => {
  const monthYear = getCurrentMonthYear();
  const message = `Booking available for ${monthYear}`;

  return (
    <MarqueeContainer>
      <MarqueeText variant="body1">
        {message} &nbsp; {message} &nbsp; {message} &nbsp; {message} &nbsp; {/* Repeat message for continuous effect */}
      </MarqueeText>
    </MarqueeContainer>
  );
};

export default Marquee;
