import React from 'react';
import { Box, Container } from '@mui/material';

const Hero = () => {
  return (
    <Box 
      id="hero-section" 
      position="relative" 
      sx={{
        width: '100%',
        paddingTop: '56.25%', // 16:9 aspect ratio (9 / 16 = 0.5625)
        overflow: 'hidden',
      }}
    >
      <Box
        component="video"
        autoPlay
        loop
        muted
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          objectFit: 'cover',
        }}
      >
        <source src="src/video/sample2.mp4" type="video/mp4" />
        Your browser does not support the video tag.
      </Box>
      <Container
        maxWidth={false}
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-end', // Changed to align content to the bottom
          alignItems: 'center',
          zIndex: 1,
        }}
      >
        <Box 
          component="img" 
          src="/logoLandscape.png" 
          alt="Logo"
          sx={{
            width: '80%',
            maxWidth: '60%',
            maxHeight: '30%', // Reduced to prevent overlapping with video content
            objectFit: 'contain',
            mb: 4, // Add some margin at the bottom
          }}
        />
      </Container>
    </Box>
  );
};

export default Hero;