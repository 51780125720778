import React, { useState } from 'react';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Box,
  Typography,
  IconButton,
  useMediaQuery,
  useTheme,
  Stepper,
  Step,
  StepLabel,
  Paper,
  Snackbar,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import SendIcon from '@mui/icons-material/Send';
import CelebrationIcon from '@mui/icons-material/Celebration';
import { styled } from '@mui/material/styles';
import * as Yup from 'yup';

const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    borderRadius: 24,
    padding: theme.spacing(3),
    background: 'linear-gradient(145deg, #f3f4f6 0%, #ffffff 100%)',
  },
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    borderRadius: 12,
    transition: theme.transitions.create(['box-shadow']),
    '&:hover': {
      boxShadow: '0 0 0 2px rgba(25, 118, 210, 0.2)',
    },
    '&.Mui-focused': {
      boxShadow: '0 0 0 3px rgba(25, 118, 210, 0.3)',
    },
  },
}));

const StyledSelect = styled(Select)(({ theme }) => ({
  borderRadius: 12,
  '&:hover': {
    boxShadow: '0 0 0 2px rgba(25, 118, 210, 0.2)',
  },
  '&.Mui-focused': {
    boxShadow: '0 0 0 3px rgba(25, 118, 210, 0.3)',
  },
}));

const StyledSnackbar = styled(Snackbar)(({ theme }) => ({
  '& .MuiSnackbarContent-root': {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    borderRadius: 16,
    padding: theme.spacing(2),
  },
}));

const FormModal = ({ open, onClose }) => {
  const [activeStep, setActiveStep] = useState(0);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    service: '',
    message: '',
  });
  const [errors, setErrors] = useState({});
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const validationSchema = [
    Yup.object().shape({
      name: Yup.string().required('Name is required'),
      email: Yup.string().email('Invalid email').required('Email is required'),
      phone: Yup.string().matches(/^[0-9]{10}$/, 'Phone number must be 10 digits').required('Phone number is required'),
    }),
    Yup.object().shape({
      service: Yup.string().required('Please select a service'),
    }),
    Yup.object().shape({
      message: Yup.string().required('Please tell us about your project'),
    }),
  ];

  const handleChange = (event) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
    setErrors({ ...errors, [event.target.name]: '' });
  };

  const handleNext = async () => {
    try {
      await validationSchema[activeStep].validate(formData, { abortEarly: false });
      setActiveStep((prevStep) => prevStep + 1);
    } catch (validationErrors) {
      const newErrors = {};
      validationErrors.inner.forEach((error) => {
        newErrors[error.path] = error.message;
      });
      setErrors(newErrors);
    }
  };

  const handleBack = () => {
    setActiveStep((prevStep) => prevStep - 1);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      await validationSchema[activeStep].validate(formData, { abortEarly: false });
      // Handle form submission logic here
      console.log(formData);
      setShowSuccessMessage(true);
      setTimeout(() => {
        setShowSuccessMessage(false);
        onClose();
      }, 5000);
    } catch (validationErrors) {
      const newErrors = {};
      validationErrors.inner.forEach((error) => {
        newErrors[error.path] = error.message;
      });
      setErrors(newErrors);
    }
  };

  const steps = ['Personal Info', 'Service Selection', 'Project Details'];

  return (
    <>
      <StyledDialog
        open={open}
        onClose={onClose}
        fullScreen={fullScreen}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>
          <Box display="flex" alignItems="center" justifyContent="space-between">
            <Typography variant="h4" component="div" fontWeight="bold" color="primary">
              Let's Create Magic! ✨
            </Typography>
            <IconButton edge="end" color="inherit" onClick={onClose} aria-label="close">
              <CloseIcon />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent>
          <Stepper activeStep={activeStep} alternativeLabel sx={{ mb: 4 }}>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          <Paper elevation={3} sx={{ p: 3, borderRadius: 4 }}>
            <form onSubmit={handleSubmit}>
              {activeStep === 0 && (
                <>
                  <StyledTextField
                    autoFocus
                    margin="dense"
                    id="name"
                    name="name"
                    label="Your Name"
                    type="text"
                    fullWidth
                    variant="outlined"
                    required
                    value={formData.name}
                    onChange={handleChange}
                    error={!!errors.name}
                    helperText={errors.name}
                    sx={{ mb: 3 }}
                  />
                  <StyledTextField
                    margin="dense"
                    id="email"
                    name="email"
                    label="Email Address"
                    type="email"
                    fullWidth
                    variant="outlined"
                    required
                    value={formData.email}
                    onChange={handleChange}
                    error={!!errors.email}
                    helperText={errors.email}
                    sx={{ mb: 3 }}
                  />
                  <StyledTextField
                    margin="dense"
                    id="phone"
                    name="phone"
                    label="Phone Number"
                    type="tel"
                    fullWidth
                    variant="outlined"
                    required
                    value={formData.phone}
                    onChange={handleChange}
                    error={!!errors.phone}
                    helperText={errors.phone || "Enter a 10-digit phone number"}
                    sx={{ mb: 3 }}
                    InputProps={{
                      inputProps: {
                        pattern: "[0-9]{10}",
                      },
                    }}
                  />
                </>
              )}
              {activeStep === 1 && (
                <FormControl fullWidth variant="outlined" sx={{ mb: 3 }}>
                  <InputLabel id="service-label">Service You're Interested In</InputLabel>
                  <StyledSelect
                    labelId="service-label"
                    id="service"
                    name="service"
                    value={formData.service}
                    onChange={handleChange}
                    label="Service You're Interested In"
                    required
                    error={!!errors.service}
                  >
                    <MenuItem value="social-media">Social Media Management 📱</MenuItem>
                    <MenuItem value="content-creation">Content Creation 🎨</MenuItem>
                    <MenuItem value="design">Design 🖌️</MenuItem>
                    <MenuItem value="branding">Branding 🏷️</MenuItem>
                    <MenuItem value="strategy">Strategy 🧠</MenuItem>
                    <MenuItem value="growth">Growth 📈</MenuItem>
                    <MenuItem value="other">Other 🌟</MenuItem>
                  </StyledSelect>
                  {errors.service && (
                    <Typography color="error" variant="caption">
                      {errors.service}
                    </Typography>
                  )}
                </FormControl>
              )}
              {activeStep === 2 && (
                <StyledTextField
                  margin="dense"
                  id="message"
                  name="message"
                  label="Tell Us About Your Project"
                  multiline
                  rows={4}
                  fullWidth
                  variant="outlined"
                  value={formData.message}
                  onChange={handleChange}
                  error={!!errors.message}
                  helperText={errors.message}
                  sx={{ mb: 3 }}
                />
              )}
            </form>
          </Paper>
        </DialogContent>
        <DialogActions>
          {activeStep > 0 && (
            <Button onClick={handleBack} sx={{ mr: 1 }}>
              Back
            </Button>
          )}
          {activeStep < steps.length - 1 ? (
            <Button
              onClick={handleNext}
              variant="contained"
              color="primary"
              size="large"
              sx={{
                borderRadius: 25,
                textTransform: 'none',
                fontWeight: 'bold',
                px: 4,
              }}
            >
              Next
            </Button>
          ) : (
            <Button
              onClick={handleSubmit}
              variant="contained"
              color="primary"
              size="large"
              endIcon={<SendIcon />}
              sx={{
                borderRadius: 25,
                textTransform: 'none',
                fontWeight: 'bold',
                px: 4,
              }}
            >
              Let's Collaborate!
            </Button>
          )}
        </DialogActions>
      </StyledDialog>
      <StyledSnackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={showSuccessMessage}
        message={
          <Box display="flex" alignItems="center">
            <CelebrationIcon sx={{ mr: 1 }} />
            <Typography variant="body1">
              Awesome! We've got your deets. Our squad will hit you up faster than you can say "viral tweet"! 🚀
            </Typography>
          </Box>
        }
      />
    </>
  );
};

export default FormModal;