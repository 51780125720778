// GradientBackground.jsx
import React from 'react';
import { Box } from '@mui/material';
import { styled } from '@mui/system';

const BlurredBackgroundWrapper = styled(Box)(({ theme }) => ({
  position: 'relative',
  width: '100%',
  minHeight: '100vh',
  background: 'linear-gradient(135deg, #2c3e50, #4a69bd, #6a89cc)',
  overflow: 'hidden',
}));

const BlurLayer = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backdropFilter: 'blur(100px)',
  zIndex: 1,
}));

const ExtraBlurLayer = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: -50,
  left: -50,
  right: -50,
  bottom: -50,
  background: 'radial-gradient(circle at 30% 70%, rgba(255,255,255,0.1), transparent 50%)',
  filter: 'blur(60px)',
  opacity: 0.7,
  zIndex: 2,
}));

const GlassPane = styled(Box)(({ theme }) => ({
  position: 'absolute',
  borderRadius: '50%',
  background: 'rgba(255, 255, 255, 0.1)',
  backdropFilter: 'blur(5px)',
  zIndex: 3,
}));

const ContentWrapper = styled(Box)({
  position: 'relative',
  zIndex: 4,
  width:'100%',
});

const GradientBackground = ({ children, ...props }) => (
  <BlurredBackgroundWrapper {...props}>
    <BlurLayer />
    <ExtraBlurLayer />
    <GlassPane 
      sx={{ 
        width: '400px', 
        height: '400px', 
        top: '-100px', 
        left: '-100px' 
      }} 
    />
    <GlassPane 
      sx={{ 
        width: '300px', 
        height: '300px', 
        bottom: '50px', 
        right: '50px' 
      }} 
    />
    <ContentWrapper>{children}</ContentWrapper>
  </BlurredBackgroundWrapper>
);

export default GradientBackground;