import React from 'react'
import Header from '../Components/Header'
import Hero from '../Components/Hero'
import ServicesDisplay from '../Components/ServicesDisplay'
import MissionContent from '../Components/MissionContent'
import TestimonialContent from '../Components/TestimonialContent'
import PromiseContent from '../Components/PromiseContent'
import Marquee from '../Components/Marquee'
import ServicesAndPricing from '../Components/ServicesAndPricing'
import InstagramFeed from '../Components/InstagramFeed'

const HomePage = ({ openModal }) => {
  return (
    <>
       <Hero/>
       <ServicesDisplay  openModal={openModal}/>
       <MissionContent  openModal={openModal}/>
       <TestimonialContent  openModal={openModal}/>
       <PromiseContent  openModal={openModal}/>
       <Marquee/>
       <ServicesAndPricing  openModal={openModal}/>
       <InstagramFeed/>
    </>
  )
}

export default HomePage