import React, { useState } from "react";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

import HomePage from "./Pages/HomePage";
import Header from "./Components/Header";
import Footer from "./Components/Footer";
import FormModal from "./Components/FormModal"; // Import the FormModal component
import theme from "./theme";
import "@fontsource/inter";

function App() {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOpenModal = () => setIsModalOpen(true);
  const handleCloseModal = () => setIsModalOpen(false);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Header openModal={handleOpenModal} />
      <HomePage openModal={handleOpenModal} />
      <Footer openModal={handleOpenModal} />
      <FormModal open={isModalOpen} onClose={handleCloseModal} />
    </ThemeProvider>
  );
}

export default App;