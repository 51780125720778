import React from 'react';
import { Box, Typography, Button, Container, Grid } from '@mui/material';
import { styled } from '@mui/system';

const MissionSection = styled(Box)({
  minHeight: '100vh',
  backgroundColor: '#fcf8f3',
  padding: '40px 0',
  margin:'auto',
  marginLeft:'auto',
});

const VideoContainer = styled(Box)({
  position: 'relative',
  borderRadius: '20px',
  overflow: 'hidden',
  // maxHeight:'90vh',
});

const StyledButton = styled(Button)({
  backgroundColor: '#FFEB3B',
  color: '#000',
  borderRadius: '20px',
  padding: '10px 20px',
  '&:hover': {
    backgroundColor: 'red',
    color: 'white',
  },
});

const MissionContent = () => (
  <MissionSection>
    <Container>
      <Grid container spacing={4} alignItems="center">
        <Grid item xs={12} md={7}>
          <Typography variant="h2" component="h1" gutterBottom sx={{ fontWeight: 'bold', fontFamily: 'Arial Black, sans-serif' }}>
            OUR MISSION
          </Typography>
          <Typography variant="h5" sx={{ color: '#452316' }} gutterBottom>
            Dedicated to transforming your vision into reality, at B Makers, we bring creativity and expertise together into transforming your vision into reality!
          </Typography>
          <Typography variant="body1" sx={{ color: '#452316' }} paragraph>
            As your creative partners, we combine our expertise in social media management, content creation, design, and branding to craft compelling solutions tailored to your brand. Our mission is to help you achieve growth and success with a thoughtful touch!
          </Typography>
          <StyledButton>ABOUT US</StyledButton>
        </Grid>
        <Grid item xs={12} md={5}>
          <VideoContainer>
            <video 
              src="src/video/Favorites client works _20240731_113204_0001.mp4" 
              autoPlay 
              loop 
              muted 
              style={{ width: '100%', maxHeight: '90vh', borderRadius: '20px' }}
            />
          </VideoContainer>
        </Grid>
      </Grid>
    </Container>
  </MissionSection>
);

export default MissionContent;
